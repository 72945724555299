import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Occupation, Survey, SurveyResultsSection} from "../../../../../../model/common";
import {IndustriesService} from "../../../../../../core/industries.service";
import {AuthApiService} from "../../../../../../core/auth-api.service";
import {MessageComponent} from "../../../../../../component/message";
import {BlockTripettoNumberResultComponent} from "../../result-blocks/block-tripetto-number-result/block-tripetto-number-result.component";
import {AppSettings} from 'src/app/core/app.settings';

@Component({
  selector: 'app-survey-results-occupation-result',
  templateUrl: './survey-results-occupation-result.component.html',
  styleUrls: ['./survey-results-occupation-result.component.scss']
})
export class SurveyResultsOccupationResultComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;

  @Input('survey') survey: Survey;
  @Input('occupation') occupation: Occupation;
  @Input('surveyResultsSection') surveyResultsSection: SurveyResultsSection;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: number;

  occupationQuestions = [];

  regularQuestions = [
    'job_description_match',
    'educational_requirement',
    'certification_required',
    'openings_count',
    'difficulty_filling',
    'job_title',
    'incumbents_count',
    'flsa_status',
    'hourly_rate_or_annual_salary',
  ];

  groupNumberQuestions = [
    'min_range_base_salary_fulltime',
    'max_range_base_salary_fulltime',
    'max_hire_base_salary_fulltime',
    'min_actual_base_salary_fulltime',
    'max_actual_base_salary_fulltime',
    'base_salary_fulltime',
    'other_cash_compensation',
    'min_range_rate',
    'max_range_rate',
    'min_actual_rate',
    'base_rate',
    'max_actual_rate',
    'step_progression_num_steps',
    'other_cash_compensation_hourly',
  ];

  isDrama2022 = false;
  drama2022Questions = [
    'incumbents_count'
  ];

  constructor(
    private industriesService: IndustriesService,
    private auth: AuthApiService,
  ) { }

  ngOnInit() {
    let questionsDetails = []
    questionsDetails = Object.values(this.surveyResultsSection.questions);
    
    for (const key in this.occupation['questions']) {
      let findElement = questionsDetails.find(x => x.alias == this.occupation['questions'][key]['alias'])
      this.occupation['questions'][key]['block'] = findElement.block
    }
    if (this.auth.isUser() && this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.show) {
      this.showResultsBelowSafeHarbor = AppSettings.ShowResultsBelowSafeHarbor.mention;
    }

    // this.isDrama2022 = this.isSurveyDescription("This is the DRMA Wage & Benefit Survey for 2022.");
    this.isDrama2022 = this.needDramaChanges();
  }

  needDramaChanges(){
    let d = new Date(this.survey.end_date);
    return d.getFullYear() >= 2022 && this.survey.ed_id == 19;
  }

  isSurveyDescription(surveyDescription: string): boolean {
    return this.survey.description == surveyDescription;
  }

  getQuestionTotal(question) {
    let total = 0;
    for (let answerKey of Object.keys(question['results']['answers'])) {
      let answer = question['results']['answers'][answerKey];
      if (!isNaN(parseFloat(answerKey))) {
        total += parseFloat(answerKey) * answer;
      } else {
        total += answer;
      }
    }
    return total;
  }

  areAllQuestionsLowAccuracy() {
    for (const key in this.occupation['questions']) {
      if (this.occupation['questions'].hasOwnProperty(key)) {
        const question = this.occupation['questions'][key];
        if (question.results['answered_count'] >= this.survey.safe_harbor_count) {
          return false;
        }
      }
    }
    return true;
  }

  isLowAccuracyQuestion(question) {
    if (question.results['answered_count'] < this.survey.safe_harbor_count) {
      return true;
    } else {
      return false;
    }
  }

  failToSafeHarborQuestion(question): boolean {
    return this.isLowAccuracyQuestion(question) && (this.showResultsBelowSafeHarbor !== AppSettings.ShowResultsBelowSafeHarbor.show);
  }

  failToSafeHarborSection(): boolean {
    return this.areAllQuestionsLowAccuracy() && (this.showResultsBelowSafeHarbor !== AppSettings.ShowResultsBelowSafeHarbor.show);
  }

  hideSafeHarborQuestion(question): boolean {
    return this.isLowAccuracyQuestion(question) && (this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.hide);
  }

  hideSafeHarborSection(): boolean {
    return this.areAllQuestionsLowAccuracy() && (this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.hide);
  }
}
